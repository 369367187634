//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-164:_1288,_2928,_6556,_740,_6652,_7672,_6940,_4780;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-164')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-164', "_1288,_2928,_6556,_740,_6652,_7672,_6940,_4780");
        }
      }catch (ex) {
        console.error(ex);
      }